@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Montserrat', sans-serif;
}

body {
    background-color: #f8fafc;
}

.p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #0077b6 !important;
}

.p-tabview-title {
    line-height: 2 !important;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.blink {
    animation: blink 1s infinite;
    transition: opacity 0.5s ease-in-out;
}